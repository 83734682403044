
*{
    margin:0;
    padding:0;
    box-sizing:border-box;
    font-size:12px;
    font-family: 'Arial';
  }

.App {
 min-width:100%;

 position:relative;
}

.hand:hover{
cursor:pointer
}

.banned:hover{
  cursor:not-allowed;
}
.react-tabs__tab {

  color:var(---plsGreen);
  margin:5px 1.5px;
  border:2px solid white;
  border-radius: 7px 7px 0px 0px;
}

.c {
  background: #7ABB43;
  color:white;
}
.p {
  background: #EA8D1F;
  color:white;
}
.react-tabs__tab-list {
  border-bottom: 1px solid #aaa;
  margin: 0 0 10px;
  padding: 0;

}

.react-tabs__tab--selected {
  background: #fff;
  border-color: #aaa;
  color: black;
  border-radius: 5px 5px 0 0;
}
.rows div{
  padding:6px 0px;
}
.rows:nth-child(even){
 


  }

/* REACT TABS
.react-tabs {
  -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
  border-bottom: 1px solid #aaa;
  margin: 0 0 10px;
  padding: 0;
}

.react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
}

.react-tabs__tab--selected {
  background: #fff;
  border-color: #aaa;
  color: black;
  border-radius: 5px 5px 0 0;
}

.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

.react-tabs__tab:focus {
  outline: none;
}

.react-tabs__tab:focus:after {
  content: '';
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  background: #fff;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
}
*/