.modal {
  z-index: 96000;
  position: fixed;
  top: 12vh;
  width: 90%;
  min-height:20vh;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 8px;
}

.modal__header {
  width: 100%;
  padding:0.5rem;
  background:#0E425A;
  color: green;
}

.modal__header h2 {
  font-size:calc(.9rem + .4vw);
  margin: 0.5rem;
}

.modal__content {
  padding:1rem   0.5rem;
 
}

.modal__footer {
  padding:  0.5rem;
}

@media (min-width: 768px) {
  .modal {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}

.modal-enter {
  transform: translateY(-10rem);
  opacity: 0;
}

.modal-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: all 200ms;
}

.modal-exit {
  transform: translateY(0);
  opacity: 1;
}

.modal-exit-active {
  transform: translateY(-10rem);
  opacity: 0;
  transition: all 200ms;
}
.chat-messages {
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: auto;
}
  .message {
    position:relative;
    width:100%;
    display: flex;
    align-items: center;
    margin-bottom:8px;
  }
  .content {
    position:relative;
   
    overflow-wrap: break-word;
    padding: 1rem;
    font-size: 1.1rem;
    border-radius: 1rem;
    color: #d1d1d1;

  }

  .sended {
      background-color: #2963B3;
      color:white;
  }
  .recieved {
      background-color: #002B88;
      color:white;
   
  }

.left{
  justify-content:flex-start;
}
.right{
  justify-content:flex-end;
}
@media screen and (min-width: 720px) and (max-width: 1080px) {
  .message {
    max-width: 70%;
  }
 
}