.pagination {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.pagination button {
    width: 40px;
    height: 40px;
    font-family: inherit;
    font-weight: 600;
    font-size: 16px;
    margin: 0 10px;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease;
    background: white;
    color: #0E425A;
    border-color: #eee;
}

.pagination button.active {
    font-weight: 900;
    border-color: rgb(14,66,90,.5);
    background: rgb(14,66,90,.5);
    color: white;
}