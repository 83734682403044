.lds-dual-ring {
    display: inline-block;
    width: 120px;
    height: 120px;
  }
  
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 120px;
    height: 120px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid white;
    border-color: white transparent white transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  
  .loading-spinner__overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 25vh;
    left: -5vw;
 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  