:root{
  --Header-height:50px;
  --Nav-height:30px;
  --sideNav:15%;
  --main:85%;
  --plsBlue:#0E425A;
  --plsGreen:#7ABB43;
  --headerMedium:calc(0.9375rem + .3vw);
  --headerLarge:calc(1.1rem + .3vw);
  --on:#70AD47;
  --off:rgb(237,125,49,.9);
  --incomplete:rgb(255,0,0,.3);
  --aa:rgb(255,255,0,.7);
  --pe:rgb(255,255,0,.3);
  --ready:#00FF00;
 
  
}
body {
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  min-width: 1200px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a{
  text-decoration: none;
  color:inherit;
}
/*
table{
  table-layout:fixed;
  width:100%;
  border-collapse:collapse;
  border:1px solid red;
}

thead tr{
    background-color:rgb(14,66,90,1);
    color:white;
}
        
th,td{
    border:1px solid #dddddd;
    text-align:left;
    padding:3px 6px;
}
*/
.mainrow:nth-child(odd){
background-color:rgb(0,0,0,.1);
        }

        

.edit{
  font-size:calc(.7rem + .4vw);
  color:var(--plsGreen);
  display:inline-block;
}


.mainrow_c:hover{
  background-color:var(--plsGreen);
  color:white;
  cursor:pointer;
      
  }
  
.mainrow_p:hover{
  background-color:#EA8D1F;
  color:white;
  cursor:pointer;
      
  }

.mainrow:hover  .edit{
  color:white;
}

.normalrow div,
.normalrow,
.normalrow
{
 padding:5px 0px;

} 

.receivedrow{
  padding:5px 0px;
  background-color:#FF00FF;
  border-bottom: 1px solid white;
  color:white;
}

.normalrow:nth-child(even){
  background-color:rgb(0,0,0,.1);
  color:black;
          }
          .normalrow:hover{
            background-color:var(--plsGreen);
            color:white;
            cursor:pointer;
                
            }
           
            .normalrows:nth-child(even){
              background-color:rgb(0,0,0,.1);
                      }
            .dropdown{
              position: absolute;
              top: 35px;
              right: 0px;
              display:flex;
              flex-direction:column;
 
              background-color: #7ABB43;
              border-radius: 7px;
              padding: 10 0px;
              width: 200px;
              background-color:#0E425A;
              z-index:10;
            
            }
          
            .dropdown.active{
              opacity:1;
              visibility:visible;
              transform: translateY(0);
             
            }
             
            .dropdown.inactive{
              opacity:0;
              visibility:hidden;
              transform: translateY(-20px);
            
            }

            select{
              position: relative;
    border-radius: 5px;
    width: 100%;
    border: 1px solid rgb(0,0,0,.3);
    outline: none;
    font-size: calc(.9rem + .4vw);
    height: 30px;
    padding: 6px 0px 6px 4px;
            }

            select:focus{
              border:2px solid var(--plsGreen);
            }



  .nope{
    color:red;
  }

  .banned{
    cursor:not-allowed;
  }

  .greenrow{
    background-color:#CEDCAF;
  }


  .react-datepicker__input-container > input{
    border-left:10px solid rgba(122, 187, 67, .5);
  }